var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = $('header.header').outerHeight();

$(window).scroll(function(event){
    didScroll = true;
});

setInterval(function() {
    if (didScroll) {
        hasScrolled();
        didScroll = false;
    }
}, 250);

function hasScrolled() {
    var st = $(this).scrollTop();
    
    if(Math.abs(lastScrollTop - st) <= delta)
        return;
    if (st > lastScrollTop && st > navbarHeight){
        $('header.header').addClass('header--up');
        $('.menu-dropdown').removeClass('menu-dropdown--down');
        $('.hamburger').removeClass('open');
    } else {
        if(st + $(window).height() < $(document).height()) {
            $('header.header').removeClass('header--up');
        }
    }
    
    lastScrollTop = st;
}